<script>
  import { onMount } from 'svelte';
  import './styles.css';
  onMount(() => {
    const urlParams = new URLSearchParams(window.location.search);
    if (urlParams.has('color')) {
      window.document.body.style.backgroundColor = `#${urlParams.get('color')}`;
    }
  });
</script>

<div class="app">
  <main>
    <slot />
  </main>
</div>

<style>
  .app {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
  }

  main {
    flex: 1;
    display: flex;
    flex-direction: column;
    padding: 1rem;
    width: 100%;
    max-width: 64rem;
    margin: 0 auto;
    box-sizing: border-box;
  }
</style>
